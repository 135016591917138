import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import { QandA } from '../../components/qanda'

// markup
const BayreuthPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Wagner's Bayreuth Festival | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operahouses/bayreuth" />
          <meta name="description" content="Wagner's Festspielhaus in Bayreuth, Germany. How to get tickets, how to get there and what to wear!" />
        </Helmet>
        <Hero title="Bayreuth Festival"/>
        <MainBodySection>
                    
          <Header title="The Bayreuth Festival"/>

          <p>The Bayreuth Festival is truly one of a kind. The whole enterprise was the culmination of Richard Wagner’s lifelong dream for a place to stage his operas and to this day the festival is dedicated purely to performances of Wagner’s works. Every year the short summer season features either The Ring + three other Wagner operas or in years without a Ring, five other Wagner operas.</p>
          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>The Festspielhaus is a huge structure containing an unusual auditorium that has a seating arrangement not unlike an arena with the seats fanning outwards and upwards away from the stage. There are no central aisles so everyone enters from the sides and shuffles towards the center. The orchestra pit is hidden like a black void between the front row and the stage. No one in the audience can see a single musician or the conductor.</p>

          <ImageFull>
            <StaticImage src="../../images/operahouses/bayreuth/bayreuth_house.jpg" alt="The Bayreuth Festspielhaus auditorium"/>
            <figcaption>The Bayreuth Festspielhaus auditorium</figcaption>
          </ImageFull>

          <p>Tickets have historically been monumentally difficult to acquire, an ancient system involving dubious Wagnerian societies and paper forms that left many waiting a decade or more for a chance at tickets. Recent seasons have considerably liberalised the process finally making the festival accessible to regular folks. First in 2013 a single performance was sold to the general public online, then in 2014 a whole cycle (one of the three in the festival) and a collection of other performances were sold in the same manner. However tickets will never be easy to get, with fewer than 6000 total seats available for the Ring across the whole season.</p>

          <p>Though one might expect Bayreuth to offer up fairly traditional Wagner, in reality it is at the cutting edge of operatic staging. Directors tackle the works in extremely unconventional ways with some truly bizarre results (some excellent and some less so). The last full Ring by Frank Castorf is a tough, uncompromising vision that certainly didn't please traditionalists.</p>

          <ImageFull>
            <StaticImage src="../../images/operahouses/bayreuth/Siegfried_Bayreuth_EnricoNawrath.jpg" alt="Siegfried - Act I Bayreuth"/>
            <figcaption>Act I of Castorf's Bayreuth production of <i>Siegfried</i> &copy; Enrico Nawrath/Bayreuth Festival</figcaption>
          </ImageFull>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <Header title="The Bayreuth Experience"/>

          <p>We were lucky enough to attend the Ring and Tannhäuser at Bayreuth during the summer 2014 festival. We can honestly say it is a place like few others we have visited.</p>

          <p>The audience is impeccably behaved with virtually no coughing and not a single person leaving during the act. No one applauds until the last note has drifted off into the aether. You could cut the atmosphere with a knife. Yet despite the stillness, the atmosphere before the show and during the intervals feels genuinely festive. People are there to worship Wagner's operas, but also to have a good time! Lots of alcohol is drunk and what must be thousands of Bratwurst eaten.</p>

          <p>Every performance starts at 4pm excepting the single act operas (Rheingold and Dutchman) which start at 6pm. Intervals are each an hour in length with the majority of the audience eating either at the restaurant or picking up food at the various food stands around the hill. The next act is announced by a fanfare from the balcony at the front of the house. The fanfare is always a motif from the music of the upcoming act played by brass players from the orchestra.</p>

          <p>The audience is on the older side even by operatic standards and fairly conventionally dressed, mostly in evening wear though plenty just in business attire. The vast majority, as far as we could tell, were German though over our six days we encountered a decent number of US/UK attendees as well as a solid Japanese presence.</p>

          <Header title="Getting to Bayreuth"/>

          <p>Bayreuth is a small town in the north of Bavaria, on the eastern edge of Germany near the Czech Republic. It is significant almost entirely because of the Festival, though has a few other gems as well.</p>

          <p>Getting there is reasonably easy. The closest major city is Nuremberg, about an hour by train. There aren’t that many flights into Nuremberg airport however so Munich, Dresden and Frankfurt are also viable options with train journeys of about 4 hours. Train tickets are inordinately cheap if you’re used to UK/US prices. A Bayern ticket will give you unlimited travel in Bavaria for a day for an extremely low price (especially if you’re travelling as a group as the pricing barely increases for each extra person). The trains do get busy however (and are not without delays) so do leave plenty of travel time.</p>

          <p>Getting around town is easy as virtually everything is walkable. Cabs are easy and cheap if you’re not feeling like a walk or it’s raining which it does with reasonable frequency.</p>
        
        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <Header title="FAQ: Attending Bayreuth"/>

          <QandA>
            <li>
              <h4>Is there a Dress code?</h4>
              <p>Yes, though not a heavily enforced one. The bulk of the audience are in either evening dress or business attire, fairly conservative with a little less flamboyancy than you might see at Glyndebourne. There are some who come in less formal wear but you might well feel out of place as that’s definitely the minority. Be aware of the forecast, the theatre can get incredibly hot so dress accordingly.</p>
            </li>

            <li>
              <h4>Programmes and Information?</h4>
              <p>Each opera has a rather beautiful, photo filled program available for 7 euro. It’s written in German, English and French which reduces the overall content (each article is in it three times) but we don’t read German so the multiple languages is a major plus! There is also a cast sheet sold for 1 euro dated for each performance.</p>
            </li>

            <li>
              <h4>Cloakroom?</h4>
              <p>There is a large cloakroom on the ground floor of the theatre that charges 2 euro per item. It’s well staffed so waiting isn’t much of a problem and the theatre itself doesn’t have a great deal of excess space per seat so it’s worth using if you have any larger items.</p>
            </li>

            <li>
              <h4>Food and Drink?</h4>
              <p>Wagner operas are long and at Bayreuth all intervals are an hour in length. Eating and drinking therefore is part and parcel of the Festival experience. There’s a decent, but fairly pricey, restaurant as well as a range of food stands selling a range of foodstuffs from sandwiches and cheese plates to bratwurst and cakes.</p>

              <p>The restaurant has the advantage that you have a reserved table to retreat to in the interval (especially when it’s raining) but wandering the grounds Bratwurst in hand has a certain charm to it!</p>
            </li>

            <li>
              <h4>Can I get in late?</h4>
              <p>They make this as impossible as any theatre we’ve ever been to. Not only do they close the doors but they also lock them. Before each act a bell sounds and then all the doors are closed and locking sounds are heard as each usher locks their door from the inside - creating the poshest prison in the world.</p>
            </li>

            <li>
              <h4>Where Can I Buy Tickets?</h4>
              <p>Getting tickets to Bayreuth has historically been something of a Herculean task. Tickets were allocated through a myriad of schemes none of which were really accessible to a regular punter. Fortunately that has been changing fast and they now sell <a href="http://tickets.bayreuther-festspiele.de/en" title="Bayreuth Ticketing">an allocation online</a>. It’s still far from easy, when the tickets are released it’s a mad dash to purchase with far more wanting tickets than the total available. The website struggled last year with the high demand but hopefully will work better in future</p>
            </li>

            <li>
              <h4>Sold Out Shows?</h4>
              <p>Every single show is sold out well ahead of the festival. There are no discounts ever. However this results in a good deal of returns some of which are offered through the website. Check regularly, you never know!</p>

               <p>More commonly people wait outside the theatre before each performance with signs asking for tickets and we’ve heard of many having success. Many will wait even if they fail to get seats for the first act and try again after the interval as some leave before the end.</p>
            </li>
          </QandA>

        </MainBodySection>
    </Layout>
  )
}

export default BayreuthPage
